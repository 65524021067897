import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  QuickLinkButton,
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const RecordingEquipmentPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Recording Equipment Reviews and Tutorials | Jimmy Ether"
        ogDescription="Free music production tutorials including discussions about songwriting philosophy, recording techniques, mixing tips, releasing music, etc."
        image="https://jimmyether.com/images/recording-equipment.jpg"
        url="https://jimmyether.com/recording-equipment/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Recording Equipment Reviews and Tutorials</h1>
            <p>
              While this is not a review website for studio gear, from time to time I like to highlight gear and software that I find useful when making music. When I do this, the review is unsponsored and unless otherwise stated, I have had no contact or relationship with the manufacturer. Typically, these reviews are positive ones. I wouldn't waste time covering something I don't like. But I will cover the pros and cons in context of different recording or performance situations. The point of these articles and videos is to help you better decide if that gear is right for you.
            </p>
            <p>
              Also, I'm a gear head and I just like talking about the stuff. Check out the links below and look for me on <a href="https://www.youtube.com/channel/UC_wJrTHrWsYY3VvNxYMkBfg" target="_blank">YouTube</a> and <a href="https://www.tiktok.com/@jimmyether" target="_blank">TikTok</a> for more. You can also <a href="/live-streaming-music-production">find me livestreaming</a> on those an other social media platforms each afternoon if you have questions.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/recording-equipment.jpg" alt="Recording Equipment Reviews and Tutorials" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Recent Recording Gear Videos and Articles</h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Demonstration of CADM179 Microphone</h3>
            <p>
              Demonstration and review of the inexpensive CAD M179 Microphone with stereo examples used as a Blumlein pair.
            </p>
            <p>
              <QuickLinkButton
                content={"Read More or Watch Video"}
                url={"/cad-m179-review/"}
                icon={"play circle"}
              />
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/CADM179.jpg" alt="Demonstration of CADM179 Microphone" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default RecordingEquipmentPage
